.cards{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 2px 10px 0px rgba(82,82,82,0.30);
    -webkit-box-shadow: 1px 2px 10px 0px rgba(82,82,82,0.30);
    -moz-box-shadow: 1px 2px 10px 0px rgba(82,82,82,0.30);
    border-radius: 10px;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: overlay;
}

.cards :is(p, a) {
    margin-inline: 20px;
}

.overlay{
    background-color: rgba(var(--pocoGreenRGB), .9);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s;
}

.overlay *{
    color: white;
    text-decoration: none;
    font-weight: 700;
    font-size: 170%;
}