header{
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 999;
    height: 70px;
    padding-left: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--transparentGrey);
    backdrop-filter: blur(1px);
}
#nav-logo{
    height: 60px;
    width: auto;
}

#nav-link-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
}

#nav-link-wrapper a{
    font-family: Roboto, sans-serif;
    color: black;
    text-decoration: none;
}

.current nav {
    color: var(--pocoRed);
}



.nav-link-dropdown-container {
    position: relative;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: 0px;
  }
  
  .nav-link-dropdown-container:hover .dropdown-menu {
    display: block;
  }
  
  .dropdown-menu nav {
    padding: 10px 20px;
    white-space: nowrap;
  }
  
  .dropdown-menu nav:hover {
    background-color: #f0f0f0;
  }

/* Base styles for the burger icon */
.burger-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 4px;
  position: relative;
  margin-top: 10px;
  padding-bottom: 5px;
}

.burger-icon span {
  transition: transform 0.5s ease;
  display: block;
  width: 25px;
  height: 3px;
  background-color: #000;
}

.burgerVisible :is(:last-child){
  transform: rotate(45deg);
  position: relative;
  top: -3px;
}

.burgerVisible :is(:first-child){
  transform: rotate(-45deg);
  position: relative;
  top: 3px;
}

.burgerVisible :is(:nth-child(2)){
  display: none;
}

.dropDown::after{
  display: inline-block;
    margin-left: 7px;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid rgba(0, 0, 0, 0);
    border-bottom: 0;
    border-left: .3em solid rgba(0, 0, 0, 0);
}



/* Show burger icon and hide nav links on small screens */
@media screen and (max-width: 850px) {
header{
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-left: 0px;
}

  #nav-link-wrapper {
      display: none; /* Hide nav links by default on small screens */
      flex-direction: column;
      width: 100vw;
      background-color: #fff;
      position: absolute;
      top: 100px; /* Adjust based on header height */
      left: 50%;
      transform: translateX(-50%);
      padding: 10px;
      box-shadow: 0px 6px 24px -9px rgba(0,0,0,0.52);
-webkit-box-shadow: 0px 6px 24px -9px rgba(0,0,0,0.52);
-moz-box-shadow: 0px 6px 24px -9px rgba(0,0,0,0.52);
  }
  
  #nav-link-wrapper.visible {
      display: flex; /* Show nav links when menu is open */
  }

  .burger-icon {
      display: flex;
  }
}
