.home{
    height: calc(100vh - 70px);
    background-image: url("../assets/datatrack_frontpage.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    padding-inline: 20vw;
    padding-bottom: 70px;
}

.home *{
    z-index: 2;
}

.home p{
    text-shadow: 3px 3px 20px rgba(255, 255, 255, 0.31);;
}

.home::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 70px);
    background: rgb(100,99,107);
    background: linear-gradient(180deg, rgba(100,99,107,0) 30%, rgba(255,255,255,1) 65%);
    opacity: .8;
    z-index: 1;
}

#home-img-bg{
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
}

#h1Home{
    font-size: 5rem;
    color: white;
    text-shadow: 3px 3px 20px rgba(0,0,0,0.31);
    margin: 0px auto;
}

#h1Home :is(strong){
    text-shadow: 3px 3px 20px rgba(255, 255, 255, 0.31);
}

#red{
    color: var(--pocoRed);
}

@media screen and (max-width: 850px) {
.home{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
}

    #h1Home{
        font-size: 10vw;
    }

    .home :is(p){
        display: none;
    }
}