.trainers{
    display: block;
    margin-top: 110px;
}

#trainers-grid{
    display: grid;
grid-template-columns: repeat(3, 1fr);
grid-column-gap: 10px;
grid-row-gap: 50px;
}

.card-footer{
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    height: 50px;
    background-color: var(--transparentGrey);
    padding-top: 10px;
}

.linkedinLogo{
    height: 70%;
    width: auto;
}

.bookButton{
    margin-bottom: 10px;
    padding: 15px;
    
}

.placeholderButton{
    visibility: hidden;
}

.trainersPortrait{
    width: 120px;
    height: 150px;
}

@media screen and (max-width: 1000px){
    #trainers-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 700px){
    #trainers-grid{
        grid-template-columns: repeat(1, 1fr);
    }
}