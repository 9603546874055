article{
    margin-top: 100px;
    width: 70vw;
    max-width: 800px;
    margin-inline: auto;
}

article main{
    margin: 0px;
}

article main img{
    width: 100%;
    height: auto;
}

.categories{
    padding: 3px 7px;
    border-radius: 7px;
    border: 1.5px black solid;
    opacity: 40%;
    font-size: 0.7rem;
    margin-right: 10px;
}

.cat{
    display: flex;
}