#mainFooter{
    bottom: 0px;
    height: 70px;
    background-color: var(--darkGrey);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 5vw;
    color: white;
}

#mainFooter img{
    object-fit: contain;
    height: 55%;
    padding-left: 30px;
}

@media screen and (max-width: 450px) {
    #mainFooter p{
        font-size: 0.8rem;
    }
}