/*General Rules that apply to all  the pages and components*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root{ /*Declaring constants*/
 --pocoRed: #B23149;
 --pocoGreen: #6DBDB2;
 --pocoGreenRGB: 109, 189, 178; 
 --transparentGrey: rgba(211, 211, 211, 0.505);
 --darkGrey: #333333;
 scroll-padding-top: 90px;
}

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
      
  *, *:before, *:after {
     box-sizing: inherit;
  }

Router, Routes, Route {
    margin: 0px;
    padding: 0px;
}

body{
    margin: 0px;
}

main{ /*Do not use for homepage*/
   margin-top: 70px;
   margin-inline: 10vw;
   margin-bottom: 100px;
   display: flex;
   min-height: calc(100vh - 240px);
}

main img {
    width: 100%;
    max-width: 800px;
    max-height: 800px;
}

h1, h2, h3 ,h4 ,h5 ,h6{
    font-family: "Roboto", sans-serif;
    font-style: normal;
    color: var(--pocoRed);
}

h3 ,h4 ,h5 ,h6 {
    color: #000;
}

p{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.1rem;
    line-height: 1.5;
}

h1{
    font-size: 3rem;
    font-weight: 700;
}

h2{
    font-size: 2rem;
    font-weight: 700;
}

h3{
    font-size: 1.5rem;
    font-weight: 500;
}

a{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: var(--pocoGreen);
    text-decoration: none;
}

a:hover{
    color: var(--pocoRed);
    text-decoration:underline;
}

nav {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
    margin: 5px;
}

nav:hover{
    color: var(--pocoRed);
}

img{
    object-fit: cover;
}

button{
    background-color: var(--pocoGreen);
    border: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    padding: 5%;
    border-radius: 10px;
    cursor: pointer;
}

button:hover{
    background-color: var(--pocoRed);
}

ol, ul {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.addmarginBottom{
    margin-bottom: 50px;
}

.spacer{
    height: 20px;
}

table{
    width: 100%;
    max-width: 95vw;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 1.1rem;
        border-collapse: collapse;
}

tr {
    line-height: 35px;
}

tr:hover{
    background-color: var(--transparentGrey);
}

tr :has( a strong){
    padding-top: 20px;
}



