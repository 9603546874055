#postList section{
    width: 100%;
}

#articles :is(td, th) {
    text-align: center;
}

#articles :is(:nth-child(1), :nth-child(2)) {
    text-align: left;
}

.articleTitle{
    padding-inline: 15px;
    padding-top: 15px;
}