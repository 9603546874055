#syllabusGrid{
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-column-gap: 15px;
grid-row-gap: 30px;
}

.span2{
    grid-column: span 2;
    padding-inline: 15%;
}

.flex {
    display: flex;
    justify-content: space-around;
}

.tapIndicator{
    display: none;
}

@media screen and (max-width : 800px ) {
    #syllabusGrid{
        grid-template-columns: repeat(1, 1fr);
    }

    .span2{
        grid-column: span 1;
        padding-inline: 15%;
    }

    #syllabusGridTitle{
        visibility: hidden;
    }

    
}

@media (hover : none) {
    .tapIndicator{
        display: inherit;
    }
}